// Bildschirm zu klein
.deviceSize {
	position: fixed;
	display: none;
	z-index: 9999999999999999999999999999999999999999999;
	width: 100%;
	height: 100%;
	min-height: 600px;
	top: 0;
	left: 0;
	background: #252525;
	padding: 10px;
	.deviceSizeContainer {
		width: 100%;
		max-width: 700px;
		background: #fff;
		position: relative;
		padding: 30px;
		left: 50%;
		transform: translate(-50%, 0);
		&:after {
			content: "";
			position: absolute;
			left: 50%;
			bottom: -18px;
			height: 0;
			width: 0;
			transform: translate(-50%, 0);
			border-top: 19px solid white;
			border-left: 19px solid transparent;
			border-right: 19px solid transparent; }

		h1 {
			color: $magenta;
			font-family: $sb;
			font-size: 1.2em;
			text-align: center;
			line-height: 1.4; }

		.screenAnim {
			width: 100%;
			height: 150px;
			margin: 30px auto;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			.screenSize {
				width: 200px;
				height: 140px;
				border: 8px solid #252525;
				border-radius: 4px;
				background: $medium-grey;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				animation: screen 4s infinite;
				p {
					color: white;
					background: inherit;
					padding: 5px;
					position: relative;
					z-index: 99;
					text-align: center;
					font-size: 12px; }

				.arrowH {
					width: 100%;
					height: 2px;
					background: #fff;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					&:before {
						content: "";
						position: absolute;
						width: 8px;
						height: 8px;
						border-top: 2px solid white;
						border-right: 2px solid white;
						transform: rotate(-135deg);
						top: -4px;
						left: 1px; }

					&:after {
						content: "";
						position: absolute;
						width: 8px;
						height: 8px;
						border-top: 2px solid white;
						border-right: 2px solid white;
						transform: rotate(45deg);
						top: -4px;
						right: 1px; } }


				.arrowV {
					height: 100%;
					width: 2px;
					background: #fff;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					&:before {
						content: "";
						position: absolute;
						width: 8px;
						height: 8px;
						border-top: 2px solid white;
						border-right: 2px solid white;
						transform: rotate(-45deg);
						left: -4px;
						top: 1px; }

					&:after {
						content: "";
						position: absolute;
						width: 8px;
						height: 8px;
						border-top: 2px solid white;
						border-right: 2px solid white;
						transform: rotate(135deg);
						left: -4px;
						bottom: 1px; } } } } } }





@keyframes screen {
	0%,
	10%,
	100% {
		background: rgba(154, 0, 2, 1.00);
		width: 100px;
		height: 140px; }

	40%,
	50% {
		background: rgba(154, 0, 2, 1.00);
		width: 200px;
		height: 60px; }

	80%,
	90% {
		background: rgba(63, 164, 77, 1.00);
		width: 200px;
		height: 140px; } }





@media (max-width: 760px) {
  .deviceSize {
    display: block!important;
    .app-content {
      z-index: 0; } } }

@media (max-height: 634px) {
	.deviceSize {
    display: block!important; }
	.app-content {
    z-index: 0; } }
