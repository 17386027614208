#app {
  margin: 0 auto;
  text-align: center;
  width: 95vw;
  max-width: 1400px;
  position: relative;
  max-height: 100vh;
  min-height: 55vh;
  display: flex;
  align-items: center;
  top: 140px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    #config01 {
      margin-top: 100px; } }
  .step {
    border: none;
    display: none;
    height: auto;
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 20px;
    &.active {
      display: flex; } }
  #config01 {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: nowrap;

    .choose {
      color: white;
      text-align: center;
      display: flex;
      flex: 1 0 0;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      transition: all 300ms ease;
      img {
        transition: all 300ms ease;
        display: block;
        margin: 0 auto 10px;
        position: relative;
        bottom: 0; }
      &:hover {
        img {
          bottom: 10px; } } } }
  #config02 {
    align-self: flex-start; }
  .preview {
    background: white;
    min-width: 250px;
    .previewHeading {
      background: $magenta;
      padding: 8px 10px;
      margin: 5px;
      font-family: $gb;
      color: white;
      position: relative;
      &:before {
        content: "";
        background: inherit;
        width: 12px;
        height: 12px;
        position: absolute;
        left: 50%;
        bottom: -6px;
        transform: translate(-50%) rotate(-45deg); } }
    .previewImage {
      padding: 60px 20px 40px; }
    .previewText {
      text-align: left;
      padding: 20px;
      h3 {
        color: $magenta;
        font-family: $sb;
        font-size: 1rem;
        margin-bottom: 20px; } } }

  .configurator {
    text-align: left;
    width: 100%;
    .configBody {
      display: flex;
      flex-wrap: wrap; }
    .configHeader {
      margin-left: 20px;
      max-width: 100%;
      height: 40px;
      background: $blue;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      z-index: 99;
      h2 {
        color: white;
        font-family: $sb;
        font-weight: normal;
        line-height: 100%;
        margin-top: 3px; }
      #next, #nextFunktion {
        appearance: none;
        border: none;
        outline: none;
        height: 100%;
        padding: 0 30px;
        color: white;
        background: rgba(white, 0.2);
        opacity: 0.25;
        // pointer-events: none
        &.active {
          transition: all 300ms ease;
          opacity: 1;
          pointer-events: all;
          cursor: pointer;
          &:hover {
            background: rgba(white, 0.3); } } } }
    .configSection {
      flex: 1 0 0;
      min-width: 300px;
      max-width: 100%;
      background: rgba(white, 0.8);
      margin: 20px 0 0 20px;
      padding-bottom: 10px;
      .configSectionHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgba($grey, 0.25);
        margin-bottom: 10px;
        label {
          font-family: $gb;
          span {
            font-family: Soho Gothic W02 Regular;
            font-size: inherit; } } }
      input {
        pointer-events: none; }

      .customCheckbox {
        top: 2px;
        width: 15px;
        height: 15px;
        background: $bright-grey;
        border: 1px solid lighten($light-grey, 20%);
        display: inline-block;
        margin-right: 7px;
        position: relative;
        margin-top: 4px;
        opacity: 0.5; }
      .icons {
        height: 15px;
        img {
          margin-left: 5px;
          cursor: pointer; } }

      label {
        color: $medium-grey;
        font-size: 0.925rem;
        margin-left: 5px;
        cursor: pointer;
        pointer-events: none;
        &.noCheckbox {
          margin-left: 25px; } }
      .customInput {
        display: none;

        &:checked ~ .checkbox {
          .customCheckbox {
            &:before {
              content: "";
              position: absolute;
              background: $magenta;
              width: 5px;
              height: 2px;
              transform: rotate(45deg);
              left: 2px;
              bottom: 4px; }
            &:after {
              content: "";
              position: absolute;
              background: $magenta;
              width: 8px;
              height: 2px;
              transform: rotate(-45deg);
              left: 4px;
              bottom: 5px; }
            &.preChecked {
              &:before {
                background: $blue; }
              &:after {
                background: $blue; } }
            &.preDefined {
              &:before {
                background: $medium-grey; }
              &:after {
                background: $medium-grey; } } } }


        label.checkbox {
          margin-left: 0px; } }

      .configSectionBody {
        padding: 10px 10px 10px 35px;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        hr {
          opacity: 0.5;
          margin-bottom: 10px; }
        .configBodyInputGroup {
          min-width: 50%;
          max-width: 100%;
          margin-bottom: 10px; } }
      &.active {
        background: rgba(white, 1);
        .configSectionHeader {
          border-bottom: 1px solid rgba($bright-grey, 1); }
        .customCheckbox {
          opacity: 1; }
        .alert {
          display: none; }
        input, label {
          pointer-events: all; } } } } }


.formHeading {
  font-size: 0.925rem;
  color: $medium-grey;
  margin-bottom: 10px;
  display: block;
 }  // height: 30px










