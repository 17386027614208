.backgroundStart {
    background: #3B3C3D;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0; }
.soft-row-start {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    .col-1 {
        padding: 60px 20px 0 20px;
        border-left: 1px solid $magenta;
        text-align: left;
        &:first-child {
            margin-right: 30px; }
        h1 {
            font-family: $g;
            font-size: 1.8rem;
            color: white;
            padding: 0;
            text-align: left;
            margin-bottom: 20px;
            strong {
                font-size: inherit; } }
        img {
            max-width: 100%;
            width: 300px; }
        .reflection {
            background: linear-gradient(to bottom, rgba($warm-greyP5, 0.3), rgba($warm-greyP5, 0));
            width: 100%;
            height: 50px;
            top: -5px; } } }

#gewinnspiel {
    max-width: 850px;
    margin: 0 auto;
    background: #E3DFDB;
    width: 100%;
    .gewinnspielHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        text-align: left;
        h2 {
            font-size: 1.4rem;
            color: $medium-grey;
            display: inline-block; }
        .info {
            width: 15px;
            display: inline-block;
            img {
                width: 15px; } } }
    .gewinnspielContent {
        display: flex;
        overflow: hidden;
        min-height: 425px; }
    .soft-row {
        display: flex;
        min-width: 100%;
        .col-1 {
            background: #fff;
            text-align: left;
            width: 50%;
            max-width: 50%;
            .sternchentext {
                color: $medium-grey;
                font-size: 12px;
                strong {
                    font-size: inherit; } }
            .inputGroupForm {
                margin: 30px 0;
                input {
                    appearance: none;
                    border-radius: none;
                    width: 100%;
                    padding: 10px;
                    border: 1px solid $light-grey;
                    background: $bright-grey;
                    outline: none;
                    margin-bottom: 20px; } }
            &:first-child {
                padding: 20px 20px 70px; }

            input[type="radio"], input[type="checkbox"] {
                display: none;
                position: absolute;
                left: -100000px;

                &:checked~.customCheckbox {
                    &:before {
                        content: "";
                        position: absolute;
                        background: $magenta;
                        width: 7px;
                        height: 3px;
                        transform: rotate(45deg);
                        left: 2.5px;
                        bottom: 5px; }
                    &:after {
                        content: "";
                        position: absolute;
                        background: $magenta;
                        width: 12px;
                        height: 3px;
                        transform: rotate(-45deg);
                        left: 5px;
                        bottom: 7px; } } }
            label {
                font-family: $gb;
                font-size: 1.2rem;
                color: $medium-grey;
                margin-top: 10px;
                display: block;
                cursor: pointer;
                line-height: 20px;
                .customCheckbox {
                    width: 20px;
                    height: 20px; } }
            .question {
                font-size: 1.4rem;
                font-family: $sb;
                color: $medium-grey;
                margin-bottom: 20px; }

            .quizImage {
                background: $warm-grey;
                width: 100%;
                height: 100%; }
            .img-q1 {
                background: url('../img/img_frage1.jpg') center no-repeat;
                background-size: cover; }
            .img-q2 {
                background: url('../img/img_frage2.jpg') center no-repeat;
                background-size: cover; }
            .img-q3 {
                background: url('../img/medium/04_sprite.jpg') center no-repeat;
                background-size: cover; } } }

    .btns {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 20px;
        z-index: 99999999999;
        .next {
            background: $magenta;
            color: white;
            margin-top: 30px;
            padding: 7px 20px;
            border: none;
            outline: none;
            width: 130px;
            font-size: 1.2rem;
            font-family: $sb;
            cursor: pointer;
            display: inline-block;
            text-align: center; }
        .prev {
            background: $light-grey;
            color: white;
            margin-top: 30px;
            padding: 7px 20px;
            border: none;
            outline: none;
            width: 130px;
            font-size: 1.2rem;
            font-family: $sb;
            margin-right: 10px;
            cursor: pointer;
            display: inline-block;
            text-align: center; }
        input[type="submit"] {
            appearance: none;
            background: $magenta;
            color: white;
            margin-top: 30px;
            padding: 7px 20px;
            border: none;
            outline: none;
            width: 130px;
            font-size: 1.2rem;
            font-family: $sb;
            cursor: pointer;
            border-radius: 0; } }

    .frage {
        transition: margin 400ms ease; }
    #q4 {
        height: 0;
        transition: all 400ms ease;
        h2 {
            color: white;
            font-size: 1.4rem;
            font-family: $sb;
            margin-bottom: 30px; }
        &.active {
            height: auto; }
        .col-1 {
            &:last-child {
                .soft-row {
                    align-items: center;
                    margin-bottom: 20px;
                    .col-1 {
                        background: none;
                        padding: 0;
                        text-align: center;

                        p {
                            color: white;
                            font-size: 1.2rem;
                            text-align: left;
                            strong {
                                font-size: inherit; } }
                        img {
                            max-width: 100%; } } } }
            .img-q4 {
                padding: 20px;
                background: $medium-grey; } } } }
#q5 {
    background: radial-gradient(at 50% 80% , $bright-grey, $light-grey);
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h2 {
        color: white;
        font-size: 2rem;
        font-family: $sb;
        text-shadow: 0 0 5px rgba($grey, 0.4);
        display: block;
        margin-bottom: 30px; }
    .button {
        background: $magenta;
        color: white;
        font-size: 1.2rem;
        font-family: $sb;
        padding: 10px 20px; } }
#popupInfo2.small {
    .popupContent {
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);
        padding: 60px;
        h2 {
            font-size: 1.6rem;
            color: $magenta;
            text-align: center;
            font-family: $sb; } } }










