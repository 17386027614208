input[type=range] {
  height: 46px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%; }

input[type=range]:focus {
  outline: none; }

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  background: #ECE8E4;
  border-radius: 5px;
  border: 1px solid #B1AFAE; }

input[type=range]::-webkit-slider-thumb {
  border: 1px solid darken($magenta, 5%);
  height: 38px;
  width: 38px;
  border-radius: 38px;
  background: linear-gradient(to top, $magenta, lighten($magenta, 30%)) url('../img/range_icon.svg') center no-repeat;
  background-size: cover 80%;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -17px; }

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ECE8E4; }

input[type=range]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  background: #ECE8E4;
  border-radius: 5px;
  border: 1px solid #B1AFAE; }

input[type=range]::-moz-range-thumb {
  border: 1px solid darken($magenta, 5%);
  height: 38px;
  width: 38px;
  border-radius: 38px;
  background: linear-gradient(to top, $magenta, lighten($magenta, 30%)) url('../img/range_icon.svg') center no-repeat;
  background-size: cover 80%;
  cursor: pointer; }

input[type=range]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent; }

input[type=range]::-ms-fill-lower {
  background: #ECE8E4;
  border: 1px solid #B1AFAE;
  border-radius: 10px;
  box-shadow: 0px 0px 0px #000000; }

input[type=range]::-ms-fill-upper {
  background: #ECE8E4;
  border: 1px solid #B1AFAE;
  border-radius: 10px;
  box-shadow: 0px 0px 0px #000000; }

input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.15);
  border: 1px solid linear-gradient(to top, darken($magenta, 20%), lighten($magenta, 40%));
  height: 38px;
  width: 38px;
  border-radius: 38px;
  background: linear-gradient(to top, $magenta, lighten($magenta, 30%));
  cursor: pointer; }

input[type=range]:focus::-ms-fill-lower {
  background: #ECE8E4; }

input[type=range]:focus::-ms-fill-upper {
  background: #ECE8E4; }
