#top-nav {
    width: 100%;
    height: 62px;
    display: flex;
    background: #252525;
    padding: 14px;
    border-bottom: 1px solid #151515;
    z-index: 9999999;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    top: 0;
    .home {
        width: 40px;
        height: 40px;
        background: linear-gradient(#757474, #313030);
        position: relative;
        padding: 5px;
        border-radius: 2px;
        margin-left: 30px;
        box-shadow: 0 1px 0 rgba(black, 0.5), 0 -1px 0 rgba(white, 0.5);
        transition: all 300ms ease;
        .hover {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background: #757474;
            opacity: 0;
            z-index: 0;
            transition: all 300ms ease;
            border-radius: 2px; }

        &:hover {
            box-shadow: 0 1px 0 transparent, 0 -1px 0 rgba(white,  0.3);
            padding: 10px;
            .hover {
                opacity: 0.5; } } }
    #logo {
        width: 90px;
        display: inline-block; }
    .navRight {
        display: flex;
        justify-content: space-between;
        align-items: center; }
    #mainInfo {
        transition: all 300ms ease;
        cursor: pointer;
        color: white;
        font-size: 12px;
        #infoIcn {
            transition: all 300ms ease;
            font-family: Soho W02 Bold, sans-serif!important;
            width: 15px;
            height: 15px;
            background-color: $medium-grey;
            background-image: url(../img/hilfe.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 40%;
            border: 1px solid $light-grey;
            display: inline-block;
            text-align: center;
            border-radius: 50%;
            padding: 2px;
            margin-left: 5px;
            top: 3px;
            position: relative; }
        &:hover {
            color: lighten($light-grey, 30%);
            #infoIcn {
                background-color: $magenta;
                color: white;
                border-color: white; } } }
    .switchBtn {
        border: 1px solid $light-grey;
        padding: 5px 32px 5px 10px;
        margin-left: 20px;
        position: relative;
        transition: all 300ms ease;
        &.bauform {
            background: $blue;
            border: 1px solid darken($blue, 10%); }
        img {
            top: 50%;
            right: 10px;
            position: absolute;
            transform: translateY(-50%); }
        &:hover {
            background: $light-grey; } } }


