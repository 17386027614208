#app.modusFunktionen {
  top: 110px;
  #reset {
    color: white;
    justify-self: flex-end; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    #config01 {
      margin-top: 0; } }
  #config02 {
    .configurator {
      display: flex;
      flex-wrap: wrap; } }
  #config03 {
    z-index: 99999; }
  #config01, #config02 {
    flex-wrap: wrap;
    .configurator {
      width: 100%;
      background: none;

      .configHeader {
        margin: 0 0 20px 0;
        width: 100%;
        z-index: 99999;
        span {
          height: 100%;
          display: flex;
          align-items: center;
          div {
            margin-right: 10px;
            font-size: 0.925rem;
            cursor: pointer; } } }

      .configBody2 {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        width: 100%;

        #versorgungBauform {
          padding: 0px 20px 0;
          margin: 0; }
        .configSection {
          flex: 1 0 0;
          min-width: 45%;
          max-width: 100%;
          background: rgba(white, 0.8);
          margin: 0 0 20px;
          padding-bottom: 10px;
          &:nth-child(even) {
            margin-right: 10px; }
          &:nth-child(odd) {
            margin-left: 10px; }
          &:nth-child(1) {
            min-width: 100%;
            margin: 0 0 20px 0; }
          &.active {
            background: white;
            @media (max-width: 1000px) {
              margin: 0 20px 20px 0;
              min-width: 100%!important;
              &:nth-child(3), &:nth-child(5) {
                margin-right: 0; } }
            .configSectionHeader {
              border-bottom: 1px solid rgba($bright-grey, 1);
              label {
                font-family: $g; }
              .customCheckbox {
                opacity: 1; }
              .alert {
                display: none; }
              input, label {
                pointer-events: all; } } }
          .configSectionHeader {
            align-items: center;
            padding: 0 10px 0 0;
            span {
              display: flex;
              align-items: center;
              font-family: $g;
              color: $medium-grey;
              font-size: 0.925rem; }
            img {
              margin-right: 10px; }
            .icons {
              justify-self: flex-end;
              .info {
                margin-right: 0; } } } }
        #configVersorgung {
          flex: 1 0 0; }
        #configAnpass {
          flex: 2 0 0; }
        .configSectionBody {
          padding: 0px 10px;
          margin: 0;

          .configBodyInputGroup {
            flex: 1 0 0;
            min-width: 50px;
            margin: 0 0 10px 20px;
            padding-right: 20px;
            &:last-child {
              margin-bottom: 0; }
            .customCheckbox {
              position: absolute;
              left: -20px;
              top: -3px; } }
          &#versorgungBatterie {
            .configBodyInputGroup {
              margin-bottom: 0; } } } } } }






  label {
    color: $medium-grey;
    font-size: 0.925rem;
    margin-left: 0px;
    cursor: pointer;
    margin-right: 10px; }

  .customInput {
    display: none;

    &:checked ~ .checkbox {
      .customCheckbox {
        &:before {
          content: "";
          position: absolute;
          background: $magenta;
          width: 5px;
          height: 2px;
          transform: rotate(45deg);
          left: 2px;
          bottom: 4px; }
        &:after {
          content: "";
          position: absolute;
          background: $magenta;
          width: 8px;
          height: 2px;
          transform: rotate(-45deg);
          left: 4px;
          bottom: 5px; }
        &.preChecked {
          &:before {
            background: $blue; }
          &:after {
            background: $blue; } }
        &.preDefined {
          &:before {
            background: $medium-grey; }
          &:after {
            background: $medium-grey; } } } } }


  #config01 {
    align-self: flex-start;
    align-items: flex-start!important;
    .configurator {

      .configBody {
        background: white;
        padding: 10px 20px;
        #versorgungCB {
          padding: 10px 0;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .icons {
            margin-top: 4px;
            height: 15px; }
          .configBodyInputGroup {
            margin: 0; } }
        dl {
          width: 100%;
          dt {
            padding: 10px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .toggleHead {
              font-family: $gb;
              color: $medium-grey;
              display: flex;
              align-items: center;
              font-size: 0.925rem;
              img.toggleThumb {
                margin-right: 20px; } }
            .arrow {
              justify-self: flex-end;
              transition: all 300ms ease; }
            &.active {
              .arrow {
                transform: rotate(180deg);
                opacity: 0.6; } } } }

        dd {
          height: 0;
          overflow: hidden;
          width: 100%;
          border-bottom: 1px solid $bright-grey;
          padding: 0;
          position: relative;
          transition: all 300ms ease;
          &:before {
            content: "";
            width: 150%;
            position: absolute;
            left: -25%;
            height: 200%;
            bottom: 0;
            box-shadow: inset 0 -3px 7px rgba(black, 0.1); }
          &:after {
            content: "";
            width: 100%;
            position: absolute;
            left: 0;
            height: 10px;
            bottom: 0px;
            background: linear-gradient(to left, white 0%, rgba(white, 0) 5%, rgba(white, 0) 95%, white 100% ); }
          .innerList {
            margin-left: 24px;
            li {
              margin-bottom: 10px; } }

          &.active {
            padding: 15px 0;
            height: auto; }

          .toggleBodyHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            h3 {
              font-family: $gb;
              color: $blue; } }
          .toggleBodyContent {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
            flex-wrap: wrap;
            &.justifyLeft {
              justify-content: flex-start; }

            .configBodyInputGroup {
              text-align: center;
              width: 120px;
              margin-right: 10%;
              &:last-child {
                margin-right: 0; }
              &.fullWidth {
                width: 100%!important;
                text-align: left;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
                margin-right: 0; } }
            &.leftContent {
              justify-content: flex-start;
              .configBodyInputGroup {
                text-align: left; } } } } }


      .configHeader {
        margin-left: 0;
        margin-bottom: 20px; } } }

  .preview {
    background: white;
    margin-right: 20px;
    min-width: 250px;
    max-width: 350px;
    z-index: 100;
    margin-bottom: 20px;
    transition: all 100ms ease;
    .bauformPreview {
      background: $bright-grey;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      max-width: 100%;

      &.imageThumbs {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          padding: 20px;
          flex-wrap: wrap;
          height: 176px;
          #minimize {
            width: 100%;
            height: 40px;
            border-bottom: 1px solid lighten($light-grey, 40%);
            position: absolute;
            left: 0;
            top: 0;
            padding: 0 10px;
            cursor: pointer;
            transition: all 300ms ease;

            .icon {
              width: 40px;
              height: 40px;
              background: $blue;
              color: white;
              display: inline-block;
              position: absolute;
              top: 0;
              left: 0;
              margin-right: 5px;

              &:after {
                content: "";
                position: absolute;
                left: 50%;
                bottom: 55%;
                transform: translate(-50%) rotate(-45deg);
                width: 10px;
                height: 2px;
                background: white; }
              &:before {
                content: "";
                position: absolute;
                left: 50%;
                top: 55%;
                transform: translate(-50%) rotate(45deg);
                width: 10px;
                height: 2px;
                background: white; }

              &:hover {

                background: lighten($blue, 10%); } } }

          h3 {
            display: block;
            width: 100%;
            text-align: center;
            color: $medium-grey;
            font-family: $g;
            font-size: 12px;
            margin-bottom: 10px;
            margin-top: 15px; }
          img {
            margin-right: 20px;
            max-width: 100%;
            &:last-child {
              margin-right: 0; } }
          .IIC_thumb {
            width: 32px;
            height: 28px;
            position: relative; }
          .CIC_thumb {
            width: 41px;
            height: 31px;
            position: relative;
            left: 5px; }
          .Kanal_thumb {
            width: 44px;
            height: 36px;
            position: relative;
            top: 0; }
          .Concha_thumb {
            width: 48px;
            height: 45px;
            position: relative;
            top: 0;
            margin-left: 5px; } } }

    .previewHeading {
      text-align: center;
      .burger {
        display: none; } }
    .previewText {
      padding: 0; }
    .bauformenPreview {
      background: white;
      margin-bottom: 20px;

      .bauformenHeader {
        width: 100%;
        background: white;
        padding: 5px;
        h3 {
          font-family: $gb;
          color: white;
          padding: 9px 20px 9px;
          background: $medium-grey;
          font-size: 0.825em;
          position: relative;
          &:before {
            content: "";
            background: inherit;
            width: 12px;
            height: 12px;
            position: absolute;
            left: 50%;
            bottom: -6px;
            transform: translate(-50%) rotate(-45deg); } } } }

    &.narrow {
      width: 40px!important;
      min-width: 40px!important;
      max-width: 40px!important;
      height: 40px!important;
      overflow: hidden;
      .bauformPreview {
        height: 0;
        width: 0;
        overflow: hidden;
        padding: 0;
        #minimize {
          display: none; } }
      .previewHeading {
        color: transparent;
        position: relative;
        padding: 5px;
        height: calc(100% - 10px);
        cursor: pointer;
        &:before {
          content: none; }
        .burger {
          position: absolute;
          display: block;
          width: 70%;
          height: 2px;
          background: #fff;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          &:before {
            content: "";
            position: absolute;
            right: 0;
            width: 80%;
            top: -5px;
            background: #fff;
            height: 100%; }
          &:after {
            content: "";
            position: absolute;
            right: 0;
            width: 80%;
            top: 5px;
            background: #fff;
            height: 100%; } } } } }



  .configOverview {
    background: white;
    .overviewHeader {
      padding: 5px;
      margin-bottom: 20px;
      h3 {
        font-family: $gb;
        color: white;
        padding: 9px 20px 9px;
        background: $magenta;
        font-size: 0.825em;
        position: relative;
        &:before {
          content: "";
          background: inherit;
          width: 12px;
          height: 12px;
          position: absolute;
          left: 50%;
          bottom: -6px;
          transform: translate(-50%) rotate(-45deg); } } } } }
#configList {
  text-align: left;
  padding: 10px 20px;
  width: 100%;
  tr {
    width: 100%;
    td {
      font-size: 0.825rem;
      color: $medium-grey;
      padding: 5px 0 5px;
      border-bottom: 1px solid $bright-grey;
      &.tableLeft {
        font-family: $gb;
        width: 10%;
        min-width: 100px; }
      &.tableRight {
        width: 50%; } } } }

.fullWidth {
  width: 100%!important;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-right: 0; }

.block {
  display: block!important; }


#versorgungBauform {
  display: flex!important;
  justify-content: space-around;
  align-items: flex-end;
  .choose {
    text-align: center;
    transition: all 300ms ease;
    border: 1px solid transparent;
    cursor: pointer;
    padding: 5px;
    font-size: 0.925rem;
    color: $medium-grey;

    img {
      display: block;
      margin-bottom: 10px; }
    &.active {
      border: 1px solid $magenta;
      color: $magenta; } } }
#app {
  .visualSelector {
    width: 100%;
    margin-top: 0px;
    &:first-child {
      border-bottom: 1px solid rgba(white, 0.1); }

    h3 {
      color: white;
      text-align: center;
      font-family: $sb;
      margin: 20px 0;
      .icons {
        margin-left: 10px;
        top: 3px;
        cursor: pointer; } }
    .soft-row {
      display: flex;
      justify-content: center;
      margin: 0;
      flex-wrap: wrap;

      label {
        text-align: center;
        color: white!important;
        display: block;
        margin: 20px;
        padding: 10px;
        border: 2px solid transparent;
        border-radius: 4px;
        &.bgVersorgung {
          background: white;
          border-top: 2px solid transparent;
          border-bottom: 2px solid transparent; }


        p {
          display: inline-block;
          padding-bottom: 5px;
          border-bottom: 2px solid transparent;
          transition: all 300ms ease;
          font-family: $g;
          font-size: 0.925rem; }
        .anpassNummer {
          display: inline-block;
          line-height: 40px;
          width: 40px;
          height: 40px;
          background: #db8915;
          margin-bottom: 20px;
          &.grey {
            background: $grey; } }


        img {
          display: block;
          margin-bottom: 15px;
          transition: all 300ms ease;
          position: relative;
          bottom: 0;
          &.imgAnpass {
            width: 100%; } }
        &:hover {
          img {
            bottom: 7px; } } }
      input:checked ~ label {
        &.bgVersorgung {
          border-top: 2px solid $magenta;
          border-bottom: 2px solid $magenta;
          box-shadow: 0 0 20px rgba($blue, 0.6);
          position: relative;
          .customCheckbox {}

          &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: -10px;
            width: 0;
            height: 0;
            border-bottom: 10px solid $magenta;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            transform: translateX(-50%); } }
        p {
          display: inline-block;
          position: relative {

            transform: translateX(-50%); } } } } } }
#configAnpass {
  .configBodyInputGroup {
    flex: 1 0 0;
    @media (max-width: 1000px) {
      flex-basis: 50%;
      .bgVersorgung {
        img {
          max-height: 150px; } } } } }




#configDevices {
  order: 8;
  margin-bottom: 0; }
#configBedienung, #configAudiologie {
  min-width: 100%!important;
  margin-left: 0!important;
  margin-right: 0!important; }
#configBatterie, #configSpule {
  flex: 1 0 0;
  min-width: 30%!important; }
#configConnect, #configPhone {
  flex: 2 0 0!important;
  min-width: 50%!important; }
#versorgungBedienung, #versorgungAudiologie {

  .col-1 {
    padding: 0 10px; }
  @media (max-width: 1000px) {
    display: block!important;
    .col-1 {
      width: 100%;
      .formHeading {
        height: 15px; } } } }
#versorgungAudiologie {
  .fullWidth {
    align-items: flex-start; } }

.configHeaderBauform {
  background: $blue;
  height: 40px;
  padding-left: 20px;
  line-height: 40px;
  color: white;
  font-family: $sb; }

#bauformDescr {
  padding: 10px 20px;
  text-align: center; }
.configBodyInputGroup.batterie {
  min-width: 25px!important; }




