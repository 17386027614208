$magenta: #c70282;
$base-font-color: #333;
$medium-grey: #606060;
$light-grey: #707070;
$grey: #393D3E;
$dark-grey: #2C3133;
$blue: #4091AD;
$bright-grey: #f0f0f0;
$warm-grey: #6B6867;
$warm-greyP5: #7E7B78;

$gb: Soho Gothic W02 Bold;
$g: Soho Gothic W02 Regular;
$sb: Soho W02 Bold;
$s: Soho W02 Regular;


// Function Font Color

@function color-contrast($color) {
    @if (lightness($color) < 40) {
        @return #ffffff; } }
