#config03 {
    max-width: 1200px;
    margin: 0 auto;
    &.active {
        display: block!important; }
    h2 {
        font-family: $sb;
        color: white;
        text-align: center;
        width: 100%;
        margin-bottom: 30px; }
    .soft-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ear {
            @supports not (-ms-high-contrast: none) {
                flex: 1 0 0; }
            max-width: 30%;
            cursor: pointer;
            img {
                width: 100%;
                max-width: 250px; }
            p {
                margin-top: 10px;
                color: white;
                font-family: $gb; } } } }

