.btn {
    padding: 10px 25px;
    background: $magenta;
    color: color-contrast($magenta);
    display: inline-block;
    border-radius: 2px;
    margin: 0 10px 10px 0;
    &:hover {
        background: lighten($magenta, 10%);
        color: color-contrast($magenta); } }
.btn-outline {
    padding: 8px 23px;
    border: 2px solid $magenta;
    color: $magenta;
    display: inline-block;
    border-radius: 2px;
    &:hover {
        background: lighten($magenta, 10%);
        color: color-contrast($magenta); } }
