
#config04 {
  align-self: flex-start;
  #ergebnis {
    display: flex;
    width: 100%;
    z-index: 99999;
    .spriteContainer {
      background: white;
      margin-right: 20px;
      .spriteImage {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        background-size: cover!important;
        &.hdoOverlay {
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          pointer-events: none;
          &.active {
            display: block; } } }
      .spriteSelection {
        padding: 0px 20px; } }

    .resultContent {
      background: white;
      @media (max-width: 1000px) {
        flex: 1 0 0; }
      .resultHeader {
        background: $magenta;
        max-width: 100%;
        padding: 10px 10px 6px;
        font-size: 1.6rem;
        color: white;
        font-family: $s;
        margin: 10px; }
      .resultBody {
        display: flex;
        padding: 0 20px 20px;
        margin-top: 30px;
        align-items: center;
        .sternchen {
          color: $medium-grey;
          font-size: 0.825rem;
          max-width: 150px;
          margin: 20px auto; }
        .resultList {
          text-align: left;
          margin-left: 20px;
          flex: 2 0 0;
          h3 {
            font-size: 1.0em;
            font-family: $sb;
            color: $magenta;
            margin-bottom: 20px; }
          ul {
            text-align: left; } } } } } }

#resultHinweis {
 margin: 20px 0 0!important;
 max-width: 100%!important; }
