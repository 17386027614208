#breadcrumb {
  width: 95vw;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  position: relative;
  top: 120px;
  z-index: 999999;
  &.fixed {
    top: 90px; }
  div[id*='bc'] {
    height: 30px;
    line-height: 32px;
    display: block;
    flex: 1 0 0;
    background: $grey;
    font-family: $s;
    text-align: center;
    position: relative;
    transition: all 300ms ease;
    color: lighten($light-grey, 10%);
    fill: $grey;
    cursor: pointer;
    font-size: 12px;
    svg {
      height: 100%;
      position: absolute;
      right: 1px;
      transform: translate(100%);
      z-index: 999;
      fill: inherit; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      svg {
        display: none; } }






    &:nth-child(even) {
      background: $dark-grey;
      fill: $dark-grey; }
    &.active {
      background: $magenta;
      font-family: $sb;
      color: white;
      fill: $magenta;
      &:hover {
        background: $medium-grey;
        fill: $medium-grey; } }
    &.activated {
      color: white;

      &:hover {
        background: $medium-grey;
        fill: $medium-grey; } } } }
