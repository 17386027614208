.background {
    background: url(../img/bg.jpg) top center no-repeat;
    background-size: cover;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0; }
.backgroundOverlay {
    background: linear-gradient(to top, rgba(#010c12, 0), #010c12, #010c12, #010c12 );
    background-size: cover;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 190px;
    z-index: 99;
    &.start {
        height: 160px; } }
.mainContent {
    min-height: 97vh; }
.col-1 {
    flex: 1 0 0; }
.col-2 {
    flex: 2 0 0; }
.col-3 {
    flex: 3 0 0; }
.col-4 {
    flex: 4 0 0; }
.col-5 {
    flex: 5 0 0; }

.required {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    font-size: 0.825rem;
    color: $medium-grey;
    font-family: $gb; }

ul {
    li {
        font-size: 0.925rem;
        color: $medium-grey;
        margin-bottom: 5px;
        position: relative;
        margin-left: 15px;
        &:before {
            content: "\25C6";
            position: absolute;
            margin-right: 5px;
            top: 0;
            left: -15px;
            font-size: 11px; } } }
ol#regularList {
    margin: 0!important;
    li {
        list-style-type: decimal;
        margin-left: 15px!important;
        margin-bottom: 0px!important; } }


.IIC {
    width: 81px;
    position: relative; }

.CIC {
    width: 103px;
    position: relative;
    left: 5px; }
.Kanal {
    width: 111px;
    position: relative;
    top: 0;
    left: -15px; }
.Concha {
    left: -15px;
    width: 136px;
    position: relative;
    top: 0; }
.customCheckbox {
    top: 2px;
    width: 15px;
    height: 15px;
    background: $bright-grey;
    border: 1px solid lighten($light-grey, 20%);
    display: inline-block;
    margin-right: 7px;
    position: relative;
    margin-top: 4px; }

// input[type="checkbox"]
//     display: none
// input[type="radio"]
//     display: none

.legaltext {
    margin-right: 20px;
    text-align: center;
    position: relative;
    a {
        margin: 5px;
        color: $medium-grey; } }


#secretButton {
    width: 47px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; }
strong {
    font-family: $gb; }

.flex {
    display: flex;
    .customCheckbox {
        margin-top: 0!important;
        min-width: 15px; } }


.sternchen, .sternchen strong {
    color: $medium-grey!important;
    font-size: 0.825rem; }


